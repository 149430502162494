import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TooltipModule } from 'ng2-tooltip-directive-ngfix';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ColorPickerModule } from 'ngx-color-picker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { OwlModule } from 'ngx-owl-carousel';
// imports: [NgxIntlTelInputModule];
import { FrameworkModule } from '@framework/framework.module';
import { SharedStoreModule } from './store';
import { NotificationService } from '@app/store/http';
import * as customControls from './components/custom-controls';
import * as layouts from './components/layout';
import * as skeleton from './components/skeletons';
import * as directives from './directives';
import * as pipes from './pipes';
import * as guards from './guards';
import * as commmons from './components/commons';
import { RouterModule } from '@angular/router';
import { SeoService } from './services/seo.service';
import { StripHtmlPipe } from "./pipes";
import * as publicStoryComp from '../story/pages/public/components';
import { PublicPage } from '@modules/company/pages/public/public.page';
import * as publicCompanyPage from '@modules/company/pages/public/components';
import * as publicFeaturedPage from '@modules/company/pages/public-featured-page/components';
import { LandingPage } from '@modules/company/pages/public-featured-page/public-featured.page';
import * as publicCampaignPage from '@modules/company/pages/public-campaign-page/components';
import { CampaignPage } from '@modules/company/pages/public-campaign-page/public-campaign.page';
import { PublicProfilePage } from '@modules/user/pages/public/public-profile.page';
import * as publicProfilePage from '@modules/user/pages/public/components';
import { ConfirmEmailComponent } from './components/commons/confirm-email/confirm-email.component';
import { QuillModule } from 'ngx-quill';
import { DragDropModule } from "@angular/cdk/drag-drop";

import { SeoSettingsModalComponent } from './components/commons/seo-settings-modal/seo-settings-modal.component';
import { InvitepeoplemodalComponent } from './components/commons/invitepeoplemodal/invitepeoplemodal.component';
import {  MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxColorsModule } from 'ngx-colors';
import {MatExpansionModule} from '@angular/material/expansion';
// 




@NgModule({
    declarations: [
        pipes.SafeHtmlPipe,
        pipes.ImageCloudinaryPipe,
        pipes.VideoPosterCloudinaryPipe,
        pipes.TrustUrlPipe,
        pipes.SafePipe,
        pipes.PrettyDatePipe,
        pipes.StripHtmlPipe,
        layouts.SiteFooterComponent,
        layouts.GsIconSpritesComponent,
        layouts.GsIconComponent,
        layouts.MainHeaderComponent,
        layouts.GsAvatarComponent,
        layouts.CommentComponent,
        layouts.CommentItemComponent,
        layouts.CommentPostComponent,
        layouts.SiteNotificationComponent,
        layouts.GsMasonryComponent,
        layouts.GsMasonryDirective,
        layouts.GsSkeletonLoaderComponent,
        layouts.ButtonStoryActionsComponent,
        layouts.ToolbarComponent,
        layouts.ToolbarItemComponent,
        layouts.ExternalHeaderComponent,
        layouts.GsIconPickerComponent,
        layouts.RequestStoryComponent,
        directives.AutoFocusDirective,
        directives.MotationObserverDirective,
        directives.ContentEditAbleEventDirective,
        directives.AutoFocusNextInputDirective,
        directives.AutoFocusPrevInputWhenDeleteDirective,
        directives.BackgroundImageDirective,
        directives.NumberOnlyDirective,
        directives.DragAndDropDirective,
        directives.ClickOutsideDirective,
        directives.TruncateDirective,
        directives.DragAndDropDirective,
        customControls.RteControlComponent,
        customControls.TextAreaAutoHeightControlComponent,
        customControls.PasswordControlComponent,
        customControls.GsChipsComponent,
        customControls.GsEmailNuggetComponent,
        customControls.GsInputMaterialComponent,
        customControls.TextAreaEmojiComponent,
        customControls.GsVerifyInputComponent,
        customControls.GsAreaMaterialComponent,
        customControls.GsAreaWysiwygComponent,
        customControls.GsSelectMaterialComponent,
        customControls.GsInputColorPickerComponent,
        customControls.GsInputClipboardComponent,
        customControls.GsTextAreaClipboardComponent,
        customControls.GsInputDateRangeComponent,
        customControls.GsInputDateComponent,
        commmons.VideoControlComponent,
        commmons.ImageLazyloadControlComponent,
        commmons.PolicyCreateComponent,
        //    commmons.VideoRecorderControlComponent,
        commmons.ImageEditorControlComponent,
        commmons.CampaignDeleteConfrimation,
        commmons.ImageCropperComponent,
        commmons.ConfirmationModalComponent,
        commmons.InfinityScrollComponent,
        commmons.GridViewDynamicFieldComponent,
        commmons.EditGuestLabelsComponent,
        commmons.GridViewLinkFieldComponent,
        commmons.GridViewComponent,
        commmons.NotesmodalComponent,
        commmons.GridViewFilterComponent,
        commmons.CropImageModalComponent,
        commmons.OwlCarouselComponent,
        commmons.OwlChildComponent,
        commmons.ShareModalContainerComponent,
        commmons.EmbedModalContainerComponent,
        commmons.SsEmbedModalContainerComponent,
        commmons.CopyVideoModalContainerComponent,
        commmons.CopyEmbedVideoModalContainerComponent,
        commmons.CopyLinkContainerComponent,
        commmons.TmEmbedModalContainerComponent,
        commmons.SsCodeViewerComponent,
        commmons.IntegrationBlankComponent,
        commmons.EmailConfirmationModalComponent,
        commmons.RssFeedLinkModalComponent,
        commmons.SeoSettingsModalComponent,
        commmons.InvitepeoplemodalComponent,
        commmons.RequestStoryCampaignContainerComponent,
        commmons.InviteGuestUsertoinbound,
        commmons.EditStepsContainerComponent,
        commmons.UniqueLinkModalComponent,
        commmons.InternaltagsComponent,
        commmons.ApprovalmodalComponent,
        commmons.AdvocacyContainerComponent,
        commmons.ChangeUserTitleComponent,
        commmons.SendThankYouPublishComponent,
        skeleton.SkeletonCommentItemComponent,
        skeleton.SkeletonStoryItemComponent,
        skeleton.SkeletonStoryListComponent,
        publicStoryComp.NudgePreviewComponent,
        publicStoryComp.StepsBarAdvocacyComponent,
        publicStoryComp.StoryToolbarComponent,
        publicStoryComp.AuthorInformationsComponent,
        publicStoryComp.CommentListComponent,
        publicStoryComp.ContributorItemComponent,
        publicStoryComp.ContributorListComponent,
        publicStoryComp.EmbedIframeContainerComponent,
        publicStoryComp.DeleteStoryContainerComponent,
        publicStoryComp.StepsBarComponent,
        publicStoryComp.NoPermissionContentComponent,
        publicStoryComp.SkeletonAuthorInformationsComponent,
        publicStoryComp.SkeletonStepBarComponent,
        publicStoryComp.StoryToolbarComponent,
        publicStoryComp.SkeletonContributorListComponent,
        publicStoryComp.SkeletonStoryToolbarComponent,
        PublicPage,
        publicCompanyPage.BannerComponent,
        publicCompanyPage.StoriesComponent,
        publicCompanyPage.CampaignsComponent,
        publicCompanyPage.CampaignItemComponent,
        publicCompanyPage.InformationsComponent,
        publicCompanyPage.SearchBoxComponent,
        publicCompanyPage.LandingPageComponent,
        publicCompanyPage.LandingPageItemComponent,
        publicCompanyPage.CompanyToolbarComponent,
        publicCompanyPage.StoryItemComponent,
        publicCompanyPage.BannerSkeletonComponent,
        publicCompanyPage.InformationsSkeletonComponent,
        publicCompanyPage.NoPermissionCompanyComponent,
        LandingPage,
        publicFeaturedPage.BannerComponent,
        publicFeaturedPage.LandingToolbarComponent,
        publicFeaturedPage.ResultComponent,
        publicFeaturedPage.SearchBoxComponent,
        publicFeaturedPage.StoryItemComponent,
        CampaignPage,
        publicCampaignPage.BannerComponent,
        publicCampaignPage.LandingToolbarComponent,
        publicCampaignPage.ResultComponent,
        publicCampaignPage.SearchBoxComponent,
        publicCampaignPage.StoryItemComponent,
        PublicProfilePage,
        publicProfilePage.UserProfileBannerComponent,
        publicProfilePage.UserProfileInformationsComponent,
        publicProfilePage.ProfileStoriesComponent,
        publicProfilePage.ProfileToolbarComponent,
        publicProfilePage.StoryItemComponent,
        publicProfilePage.UserProfileBannerSkeletonComponent,
        publicProfilePage.UserProfileInformationsSkeletonComponent,
        publicStoryComp.DeleteCampaignContainerComponent,
        publicStoryComp.DeleteSpotlightContainerComponent,
        ConfirmEmailComponent,
    ],
    imports: [
        CommonModule,
        NgxColorsModule,
        MatExpansionModule,
        FrameworkModule,
        SharedStoreModule,
        MatSlideToggleModule,
        FormsModule,
        RouterModule,
        TagInputModule,
        NgSelectModule,
        EmojiModule,
        NgxSkeletonLoaderModule,
        TooltipModule,
        BsDatepickerModule.forRoot(),
        ColorPickerModule,
        DragDropModule,
        PickerModule,
        TabsModule,
        // AngularPinturaModule,
        // NgxIntlTelInputModule,
        OwlModule,
        QuillModule.forRoot()
    ],
    exports: [
        FrameworkModule,
        PickerModule,
        // NgxIntlTelInputModule,
        NgxColorsModule,
        MatSlideToggleModule,
        NgSelectModule,
        OwlModule,
        DragDropModule,
        pipes.SafeHtmlPipe,
        pipes.ImageCloudinaryPipe,
        pipes.VideoPosterCloudinaryPipe,
        pipes.TrustUrlPipe,
        pipes.SafePipe,
        pipes.PrettyDatePipe,
        layouts.SiteFooterComponent,
        layouts.GsIconSpritesComponent,
        layouts.GsIconComponent,
        layouts.MainHeaderComponent,
        layouts.GsAvatarComponent,
        layouts.CommentComponent,
        layouts.CommentItemComponent,
        layouts.CommentPostComponent,
        layouts.SiteNotificationComponent,
        layouts.GsMasonryComponent,
        layouts.GsMasonryDirective,
        layouts.GsSkeletonLoaderComponent,
        layouts.ButtonStoryActionsComponent,
        layouts.ToolbarComponent,
        layouts.ToolbarItemComponent,
        layouts.ExternalHeaderComponent,
        layouts.GsIconPickerComponent,
        layouts.RequestStoryComponent,
        directives.AutoFocusDirective,
        directives.MotationObserverDirective,
        directives.ContentEditAbleEventDirective,
        directives.AutoFocusNextInputDirective,
        directives.AutoFocusPrevInputWhenDeleteDirective,
        directives.BackgroundImageDirective,
        directives.NumberOnlyDirective,
        directives.DragAndDropDirective,
        directives.ClickOutsideDirective,
        directives.TruncateDirective,
        directives.DragAndDropDirective,
        customControls.RteControlComponent,
        customControls.TextAreaAutoHeightControlComponent,
        customControls.PasswordControlComponent,
        customControls.GsChipsComponent,
        customControls.GsEmailNuggetComponent,
        customControls.GsInputMaterialComponent,
        customControls.TextAreaEmojiComponent,
        customControls.GsVerifyInputComponent,
        customControls.GsAreaMaterialComponent,
        customControls.GsAreaWysiwygComponent,
        customControls.GsSelectMaterialComponent,
        customControls.GsInputColorPickerComponent,
        customControls.GsInputClipboardComponent,
        customControls.GsTextAreaClipboardComponent,
        customControls.GsInputDateRangeComponent,
        customControls.GsInputDateComponent,
        commmons.VideoControlComponent,
        commmons.ImageLazyloadControlComponent,
        commmons.CopyVideoModalContainerComponent,
        commmons.NotesmodalComponent,
        commmons.AdvocacyContainerComponent,
        commmons.EditGuestLabelsComponent,
        commmons.ChangeUserTitleComponent,
        commmons.CampaignDeleteConfrimation,
        //    commmons.VideoRecorderControlComponent,
        commmons.ImageEditorControlComponent,
        commmons.RequestStoryCampaignContainerComponent,
        commmons.ImageCropperComponent,
        commmons.ConfirmationModalComponent,
        commmons.InfinityScrollComponent,
        commmons.GridViewDynamicFieldComponent,
        commmons.GridViewLinkFieldComponent,
        commmons.PolicyCreateComponent,
        commmons.GridViewComponent,
        commmons.GridViewFilterComponent,
        commmons.CropImageModalComponent,
        commmons.OwlCarouselComponent,
        commmons.OwlChildComponent,
        commmons.ShareModalContainerComponent,
        commmons.EmbedModalContainerComponent,
        commmons.CopyEmbedVideoModalContainerComponent,
        commmons.CopyLinkContainerComponent,
        commmons.SsEmbedModalContainerComponent,
        commmons.InvitepeoplemodalComponent,
        commmons.InviteGuestUsertoinbound,
        commmons.EditStepsContainerComponent,
        commmons.TmEmbedModalContainerComponent,
        commmons.SsCodeViewerComponent,
        commmons.IntegrationBlankComponent,
        commmons.EmailConfirmationModalComponent,
        commmons.SendThankYouPublishComponent,
        commmons.RssFeedLinkModalComponent,
        ConfirmEmailComponent,
        skeleton.SkeletonCommentItemComponent,
        skeleton.SkeletonStoryItemComponent,
        skeleton.SkeletonStoryListComponent,
        TooltipModule,
        BsDatepickerModule,
        TabsModule,
        QuillModule,
        StripHtmlPipe,
        // AngularPinturaModule,
        publicStoryComp.NudgePreviewComponent,
        publicStoryComp.StepsBarAdvocacyComponent,
        publicStoryComp.StoryToolbarComponent,
        publicStoryComp.AuthorInformationsComponent,
        publicStoryComp.CommentListComponent,
        publicStoryComp.ContributorItemComponent,
        publicStoryComp.ContributorListComponent,
        publicStoryComp.EmbedIframeContainerComponent,
        publicStoryComp.DeleteStoryContainerComponent,
        publicStoryComp.StepsBarComponent,
        publicStoryComp.NoPermissionContentComponent,
        publicStoryComp.SkeletonAuthorInformationsComponent,
        publicStoryComp.SkeletonStepBarComponent,
        publicStoryComp.StoryToolbarComponent,
        publicStoryComp.SkeletonContributorListComponent,
        publicStoryComp.SkeletonStoryToolbarComponent,
        PublicPage,
        publicCompanyPage.BannerComponent,
        publicCompanyPage.StoriesComponent,
        publicCompanyPage.CampaignsComponent,
        publicCompanyPage.CampaignItemComponent,
        publicCompanyPage.InformationsComponent,
        publicCompanyPage.SearchBoxComponent,
        publicCompanyPage.LandingPageComponent,
        publicCompanyPage.LandingPageItemComponent,
        publicCompanyPage.CompanyToolbarComponent,
        publicCompanyPage.StoryItemComponent,
        publicCompanyPage.BannerSkeletonComponent,
        publicCompanyPage.InformationsSkeletonComponent,
        publicCompanyPage.NoPermissionCompanyComponent,
        LandingPage,
        publicFeaturedPage.BannerComponent,
        publicFeaturedPage.LandingToolbarComponent,
        publicFeaturedPage.ResultComponent,
        publicFeaturedPage.SearchBoxComponent,
        publicFeaturedPage.StoryItemComponent,
        CampaignPage,
        publicCampaignPage.BannerComponent,
        publicCampaignPage.LandingToolbarComponent,
        publicCampaignPage.ResultComponent,
        publicCampaignPage.SearchBoxComponent,
        publicCampaignPage.StoryItemComponent,
        PublicProfilePage,
        publicProfilePage.UserProfileBannerComponent,
        publicProfilePage.UserProfileInformationsComponent,
        publicProfilePage.ProfileStoriesComponent,
        publicProfilePage.ProfileToolbarComponent,
        publicProfilePage.StoryItemComponent,
        publicProfilePage.UserProfileBannerSkeletonComponent,
        publicProfilePage.UserProfileInformationsSkeletonComponent,
        publicStoryComp.DeleteCampaignContainerComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ],
    providers: [
        NotificationService,
        SeoService,
        pipes.ImageCloudinaryPipe,
        guards.AppSettingGuard,
        guards.UserProfileGuard,
        guards.CheckUserHadCompanyGuard,
        guards.UserSettingGuard,
        guards.CompanyAdminGuard
    ]
})
export class SharedModule { }
