import { CreateUserInputApiModel } from "@modules/user/models/input";
import { QuestionaireApiModel } from "../api-models/input";

export interface CardSharedByOutputViewModel {
    data: CreateUserInputApiModel;
    isCompleted: boolean;
    questionaire: QuestionaireApiModel;
    dynamic_fields?: any
}

export class CardSharedByOutputViewModel {
    constructor() {

    }
}