import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { GetAddUserInputApiModel, GetStoryInputApiModel, GetUserInputApiModel } from '../models/api-models/input';

@Injectable()
export class PolicyHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public addPolicy(companyId: number, payload) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.post(`/${companyId}/policy`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public getPolicy(companyId: number, queryData: GetStoryInputApiModel) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.get(`/${companyId}/policy?${queryData.query}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public updatePolicy(companyId: number, policy_id, payload) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.patch(`/${companyId}/policy`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deletePolicy(companyId: number, policy_id) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.delete(`/${companyId}/policy?${policy_id}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public checkPolicySlug(companyId, slug){
        return this.get(`/${companyId}/policy?slug=${slug}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}