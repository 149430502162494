import { StoryFilterDataModel } from '@modules/company-admin/models';
import { Action } from '@ngrx/store';
import { GetStoryInputApiModel } from '../models/api-models/input';

export enum ActionTypes {

    LOAD = '[Policy] Load Policy',
    LOAD_SUCCESS = '[Policy] Load Success',
    LOAD_FAIL = '[Policy] Load Fail',

    ADD_POLICY = '[Add Policy] Add Policy',
    ADD_POLICY_SUCCESS = '[Add Policy] Add Policy Success',
    ADD_POLICY_FAIL = '[Add Policy] Add Policy Fail',


    UPDATE_POLICY = '[Update Policy] Update Policy',
    UPDATE_POLICY_SUCCESS = '[Update Policy] Update Policy Success',
    UPDATE_POLICY_FAIL = '[Update Policy] Update Policy Fail',

    DELETE_POLICY = '[Delete Policy] Delete Policy',
    DELETE_POLICY_SUCCESS = '[Delete Policy] Delete Policy Success',
    DELETE_POLICY_FAIL = '[Delete Policy] Delete Policy Fail',

    CHECK_POLICY_SLUG = '[Policy slug] Policy slug',
    CHECK_POLICY_SLUG_SUCCESS = '[Policy slug] Policy slug Success',
    CHECK_POLICY_SLUG_FAIL = '[Policy slug] Policy slug Fail',
}

export class Load implements Action{
    readonly type = ActionTypes.LOAD;
    constructor(public companyId: number, public payload: GetStoryInputApiModel ) { }
}
export class LoadSuccess implements Action{
    readonly type = ActionTypes.LOAD_SUCCESS;
    constructor(public response: any ) { }
}

export class LoadFail implements Action{
    readonly type = ActionTypes.LOAD_FAIL;
}

export class checkPolicySlug implements Action{
    readonly type = ActionTypes.CHECK_POLICY_SLUG;
    constructor(public companyId: number,public policy_Slug ) { }
}
export class checkPolicySlugSuccess implements Action{
    readonly type = ActionTypes.CHECK_POLICY_SLUG_SUCCESS;
}

export class checkPolicySlugFail implements Action{
    readonly type = ActionTypes.CHECK_POLICY_SLUG_FAIL;
}

export class AddPolicy implements Action{
    readonly type = ActionTypes.ADD_POLICY;
    constructor(public companyId: number, public payload:any ) { } ;
}

export class AddPolicySuccess implements Action{
    readonly type = ActionTypes.ADD_POLICY_SUCCESS;
}

export class AddPolicyFail implements Action{
    readonly type = ActionTypes.ADD_POLICY_FAIL;
}

export class UpdatePolicy implements Action{
    readonly type = ActionTypes.UPDATE_POLICY;

    constructor(public companyId: number, public policyId:any, public payload:any ) { }
}

export class UpdatePolicySuccess implements Action{
    readonly type = ActionTypes.UPDATE_POLICY_SUCCESS;
}

export class UpdatePolicyFail implements Action{
    readonly type = ActionTypes.UPDATE_POLICY_FAIL;
}

export class DeletePolicy implements Action{
    readonly type = ActionTypes.DELETE_POLICY;

    constructor(public companyId: number, public policy_id:any ) { }
}

export class DeletePolicySuccess implements Action{
    readonly type = ActionTypes.DELETE_POLICY_SUCCESS;
}

export class DeletePolicyFail implements Action{
    readonly type = ActionTypes.DELETE_POLICY_FAIL;
}


export type Actions = 
Load | LoadSuccess | LoadFail | AddPolicy | AddPolicySuccess | AddPolicyFail
| UpdatePolicy 
| UpdatePolicySuccess 
| UpdatePolicyFail  
| DeletePolicy 
| DeletePolicySuccess 
| DeletePolicyFail
| checkPolicySlug
| checkPolicySlugFail
| checkPolicySlugSuccess